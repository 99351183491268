import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  Box,
} from "@mui/material";
// import logo from "./img/leadsx10-logo.png";
import axios from "axios";

const UnsubscribeForm = () => {
  var endPoint = "https://backend.visionplay.fit";
  // var endPoint = "http://localhost:5555";
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [campaign, setCampaign] = useState("");
  const [org, setOrg] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const cParam = params.get("cid");
    setCampaign(cParam);
    const orgParam = params.get("oid");
    setOrg(orgParam);

    // console.log("cParam:", cParam);
    // console.log("orgParam:", orgParam);
    if (!cParam && !orgParam) setMessage("Invalid params");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(queryEmail);

    if (!email && !reason) {
      setMessage(
        "Please enter your email address and reason to submit the report"
      );
    } else {
      setLoader(true);
      try {
        const unsubscribeResponse = await axios.post(
          `${endPoint}/api/contact/complaint`,
          { oid: org, cid: campaign, email, reason },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (unsubscribeResponse.status === 200) {
          setMessage("You have successfully reported.");
        } else {
          setMessage(unsubscribeResponse.data.message);
        }

        setLoader(false);
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt="IMS Logo"
                style={{ maxWidth: "60%", marginBottom: "25px" }}
              />
            </Box> */}
            <Typography variant="h4" component="h1" gutterBottom>
              Report
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you're not sure ths email is from genuine source, enter your
              email address and a reason below and we will happily remove you
              from our list.
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                id="outlined-multiline-static"
                label="Reason"
                multiline
                rows={4}
                required
                fullWidth
                onChange={(e) => setReason(e.target.value)}
              />
              <Button
                disabled={loader}
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  marginTop: 2,
                  backgroundColor: "#094975",
                  "&:hover": {
                    backgroundColor: "#05304d",
                  },
                }}
              >
                Submit
              </Button>
            </form>
            {message && (
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{ marginTop: 2 }}
              >
                {message}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default UnsubscribeForm;
